import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subscription, timer } from 'rxjs';
import { gameListData } from '../../model/gameListData';
import { UserInfo } from '../../model/UserInfo';
import { UserInfoResponseBind } from '../../model/UserInfoResponseBind';
import { AuthenticationService } from '../../services/Auth/authentication.service';
import { ShareSlotDataService } from '../../services/share-slot-data.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BonusWinBind } from '../../model/BonusWinBind';
import { ApiSlotsService } from '../../services/slots/api-slots.service';
import { responsePlay } from '../../model/responsePlay';
import { ResponseBonusWinBind } from '../../model/ResponseBonusWinBind';
import { HostListener } from "@angular/core";

import { NbDialogService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { NgxElectronPlusService } from 'ngx-electron-plus';


@Component({
  selector: 'app-open-slot',
  templateUrl: './open-slot.component.html',
  styleUrls: ['./open-slot.component.scss']
})
export class OpenSlotComponent implements OnInit {
  source: Observable<number>;
  subscribe: Subscription;
  timerPaused: boolean = false;
  public user: UserInfo;
  // Declare height and width variables
  scrHeight:any;
  scrWidth:any;

  public attivaIframe: Boolean;
   public isMobile: Boolean;
   public isTablet: Boolean;
   public isDesktopDevice: Boolean;
   
  public jackpotlow: number;
  public jackpotmed: number;
  public jackpothigh: number;
  public jackpotnational: number;
  public jackpotlowblink: boolean;
  public jackpotmedblink: boolean;
  public jackpothighblink: boolean;
  public jackpotnationalblink: boolean;
  
  
  public data : gameListData;

  public bonusWin : BonusWinBind ;

  public iframeurl: SafeResourceUrl;

  public redir : boolean;

  public importoBonusVinto : number;
  public visualizzaDialogBonus : Boolean;
  public visualizzaDiagnostic : boolean;
  private contaRichieste : number;

  public popupjackpotinner : boolean;
  private sub: any;
  public loginViaKiosk : boolean;
  public visualizzaJackpot : boolean;
  public showSaldoUtenteAperturaSlot : boolean;

  
  public visErrore : boolean;
  public strErrore : string;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        console.log("Height : " + this.scrHeight + "Width :" + this.scrWidth);
  }

  isOrizzontal() {
    return (this.scrHeight < this.scrWidth);
  }
  constructor(private _location: Location, private router: Router, private route: ActivatedRoute,
    private authenticationService: AuthenticationService, private shareSlotData : ShareSlotDataService
    ,private deviceService: DeviceDetectorService,private apiSlot : ApiSlotsService,
    public sanitizer: DomSanitizer,private dialogService: NbDialogService,
    private electronPlusService: NgxElectronPlusService
    ) {
      this.visualizzaDiagnostic = false;
      this.attivaIframe = false;
      this.jackpotlow = 0;
      this.jackpotmed= 0;
      this.jackpothigh= 0;
      this.jackpotnational = 0;
      this.jackpotlowblink = false;
      this.jackpotmedblink= false;
      this.jackpothighblink= false;
      this.jackpotnationalblink = false;
      this.redir = false;
      this.importoBonusVinto = 0;
      this.visualizzaDialogBonus = false;
      this.contaRichieste = 0;
      this.popupjackpotinner = false;
      this.loginViaKiosk = false;
      this.visualizzaJackpot = environment.visualizzaJackpot;
      this.showSaldoUtenteAperturaSlot = environment.showSaldoUtenteAperturaSlot;
      this.visErrore  = false;
      this.strErrore = '';
    if (this.shareSlotData.game == null) {
      this.data = new gameListData()
      this.redir = true;
    } else {
      this.data = this.shareSlotData.game;
    }
      this.user = new UserInfo();
      this.epicFunction();
      this.getScreenSize();
      //this.isTablet = true; // debug
   }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      var kiosk = params['kiosk']; 
      if (kiosk != '' && kiosk != undefined ) {
        this.loginViaKiosk = true;
      }
      
   });
    if (this.redir) {
      console.log('this.data == null');
      if (this.loginViaKiosk) {
        this.router.navigate(['/home/1']);
      } else {
        this.router.navigate(['']);
      }
      
     
    } else {

    
 
      
      
        this.Play();
      
        this.start();
      }
      console.log('isTablet [' + this.isTablet + '] isOrizzontal[' + this.isOrizzontal() + ']')
      console.log('ngClass [ ' + (!this.isTablet || (this.isTablet && this.isOrizzontal())) + ']');
      console.log('ngStyle [ ' + (this.isTablet && !this.isOrizzontal()) + ']');
  }



Play() {
 

      let device = "desktop";
      if (this.isMobile) {
        device = "mobile";
      }
      console.log('play idskin[' + environment.idskin + '] id[' + this.data.id + '] device[' + device + ']' );
  this.apiSlot.play(environment.idskin, this.data.id, device,"EUR","IT","real","").subscribe(
    data => {
      this.visErrore = false;
      this.strErrore = '';
        const res = data as responsePlay;
        if (res.status == true) {
            this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(res.data as string);
            this.attivaIframe = true;
        } else {
          this.visErrore = true;
          this.strErrore = '' + res.error;
          console.log('errore play code[' + res.code + '] error[' + res.error + ']' );
        }
    },
    error => {
        console.log(error);
    });
}


clickMe() {
  
  this.visualizzaDiagnostic = !this.visualizzaDiagnostic;
  /*
  var bon = new BonusWinBind();
  bon.Amount = 10;
  bon.tipo = 21;
  bon.idBonusWin = -1;
  this.openBonusWin(bon);
  */
}

  backClicked() {
    if (this.loginViaKiosk) {
      this.router.navigate(['/home/1']);
    } else {
       this.router.navigate(['']);
    }
    //this._location.back();
  }

  AggiornaSaldo() {
    this.onPause();
    console.log('AggiornaSaldo contaRichieste['  + this.contaRichieste + ']');
    this.contaRichieste +=1;
    var checkBonus : Boolean;
    checkBonus = false;
    if (this.contaRichieste == 1 || (this.contaRichieste % 10)== 0) {
      checkBonus = true;
    }

    this.authenticationService.getBalance(checkBonus).subscribe(
      data => {
          const res = data as UserInfoResponseBind;
          if (res.status == true) {
              
              this.user = res.data as UserInfo;
              if (this.user.deleted == 1) {
                this.logOut();
                this.goToUrl(this.user.logOutRedirect);
              }
              // verifichiamo se ha vinto qualche bonus.
              if (this.user.bonusWins != null && this.user.bonusWins.length > 0 ) {
                // vincita bonus visualizziamo il modale.
                
                this.openBonusWin(this.user.bonusWins[0],this.user.id);
                
                
              } else {
                this.onResume();
              }
              if (this.user.jackpots != null && this.user.jackpots.length > 0 ) {
                this.user.jackpots.forEach(element => {
                  if (element.tipo == 81) {
                    this.jackpotlow = element.balance;
                    this.jackpotlowblink = element.blink;
                  }
                  if (element.tipo == 82) {
                    this.jackpotmed = element.balance;
                    this.jackpotmedblink = element.blink;
                  }
                  if (element.tipo == 83) {
                    this.jackpothigh = element.balance;
                    this.jackpothighblink = element.blink;
                  }
                  if (element.tipo == 84) {
                    this.jackpotnational = element.balance;
                    this.jackpotnationalblink = element.blink;
                  }
                  
                });
              }
             
          } else {
            console.log('errore getBalance code[' + res.code + '] error[' + res.error + ']' );
            this.logOut();
          }
      },
      error => {
          console.log(error);
          this.logOut();
      });
  }

  logOut() {
    this.authenticationService.logout();
      if (this.electronPlusService.isElectron) {
        this.router.navigate(['/kiosk/install']);
      } else {
        this.router.navigate([environment.defaultPageNotLoggeg]);
      }
  }
  
  start() {
    if (this.showSaldoUtenteAperturaSlot) {
      this.source = timer(0, environment.timerAggiornaSaldo);
      
      this.subscribe = this.source
        .subscribe(number => {
          if(!this.timerPaused) {
            this.AggiornaSaldo();
          }
        });
        if (Number(environment.timerAggiornaSaldo) > 9000) {
          this.AggiornaSaldo();
        }
    } else {
      this.AggiornaSaldo();
    }
  }

  onPause() {
    this.timerPaused = true;
  }

  onResume() {
    this.timerPaused = false;
  }


  ngOnDestroy() {
    if (this.subscribe != null) {
     this.subscribe.unsubscribe();
    }
   
  }

  
  ritiraBonus(idBonusWin,idUser) {
      
        this.authenticationService.ritiraBonus(idBonusWin,idUser).subscribe(
          data => {
            let req : ResponseBonusWinBind;
            req = data;
            if (req.status == true) {
            } else {
              console.log('request bonus error ' + req);
            }
          },

          errore => {
            console.log(errore);
            
          }

        );
      
  }

  AggiornaIframe() {
    console.log('chiuso');
    this.AggiornaSaldo();
  
   //this.Play();
  }


  epicFunction() {
   
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }
  
  openBonusWin(item : BonusWinBind, userid : number  ) {
    this.importoBonusVinto = item.amount;
    if (item.tipo != 21) {
      this.popupjackpotinner = true;
    } else {
      this.popupjackpotinner = false;
    }
    this.visualizzaDialogBonus = true;
    /*
    const dialogRef = this.dialogService.open(BonusWinDialogComponent, { hasBackdrop: true, closeOnBackdropClick: true, closeOnEsc : true,  context: {
      bonusWin: item,
      userid : this.user.id
    }
    });
    */
    if (this.popupjackpotinner) {
      setTimeout(()=>{                          
        this.visualizzaDialogBonus = false;
        this.importoBonusVinto = 0;
        this.ritiraBonus(item.idBonusWin,userid);
        this.onResume();
        }, 10000);
    } else {
      this.ritiraBonus(item.idBonusWin,userid);
      setTimeout(()=>{                          
        this.visualizzaDialogBonus = false;
        this.importoBonusVinto = 0;
        this.onResume();
        }, 4000);
    }
    
    
  }


  goToUrl(url): void {
     //window.location.href=url;
     if (url == null) {
      window.location.replace(environment.logOutRedirectDefault);
     } else {
      window.location.replace(url);
     }
     
  }

// fine
}
