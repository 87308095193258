import { JackpotBind } from './JackpotBind';
import { BonusWinBind } from './BonusWinBind';

export class UserInfo {
    public id: number;
    public userName: String;
    public balance: number;
    public pendingCredit: number;
    public idNetwork: number;
    public jackpots : JackpotBind[];
    public bonusWins: BonusWinBind[];
    public deleted : number;
    public logOutRedirect : string;

    constructor() {
        this.id = -1;
        this.userName = "none";
        this.balance = 0;
        this.pendingCredit = 0;
        this.idNetwork = 0;
        this.jackpots = [];
        this.bonusWins = [];
        this.deleted = 0;
        this.logOutRedirect = "http://news.google.it"; //default
    }
}