import {Directive, HostListener} from '@angular/core';
import * as screenfull from 'screenfull';

@Directive({
  selector: '[toggleFullscreen]'
})
export class ToggleFullscreenDirective {

  constructor() { }

  @HostListener('click') onClick() {
    if (screenfull.isEnabled) {
      var myElement: Element = document.querySelector( '#fullModal');
      //debugger;
      //screenfull.request(myElement);
      screenfull.toggle(myElement);
    }
}
}
