import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstallComponent } from './install/install.component';

const routes: Routes = [{
  path: '',
  children: [{
    path: 'install',
    component: InstallComponent,
    data: {
      heading: 'HEADING.KIOSKLIST'
    }
  }]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KioskRoutingModule { }
