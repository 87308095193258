import { Component, OnInit } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, timer } from 'rxjs';



@Component({
  selector: 'app-ricariche',
  templateUrl: './ricariche.component.html',
  styleUrls: ['./ricariche.component.scss']
})
export class RicaricheComponent implements OnInit {

  public nomeSito: String;
  public servizioSelezionato: Boolean;
  public nomeServizioSelezionato : String;
  public versione: Number;
  public email: String;
  private sub: any;
  public loginViaKiosk : boolean;
  private conta : number;

  source: Observable<number>;
  subscribe: Subscription;

  constructor( private route: ActivatedRoute,private router: Router) { 
    this.servizioSelezionato = false;
    this.nomeServizioSelezionato = "";
    this.nomeSito = environment.titolo;
    this.versione = environment.idskin;
    this.email = environment.email;
    this.loginViaKiosk = false;
    this.conta = 0;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      var kiosk = params['kiosk']; 
      if (kiosk != '' && kiosk != undefined ) {
        this.loginViaKiosk = true;
      }
      
   });
  
   this.source = timer(0, 5000);
    
   this.subscribe = this.source
     .subscribe(number => {
        this.conta = 0;
     });

  }

  selezionaServizio(nome: String) {
    this.nomeServizioSelezionato = "assets/images/ui/" + nome + ".png";
    console.log(this.nomeServizioSelezionato);
    this.servizioSelezionato = true;
  }

  tornaHome() {
    this.nomeServizioSelezionato = "";
    this.servizioSelezionato = false;
  }

  autologin()
  {
    if (this.loginViaKiosk) {
      console.log('click conta['  + this.conta + ']');
      this.conta = this.conta + 1;
      if (this.conta>2) {
        this.router.navigate(['/ricariche/1?autologin']);
      }
    }
   
  }


  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
}
