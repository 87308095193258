import { Component, OnInit } from "@angular/core";
import { NbSidebarService, NbDialogService } from "@nebular/theme";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../services/Auth/authentication.service";
import { environment } from "../../../environments/environment";
import { Vendor } from "../../model/Vendor";
import { gameListData } from "../../model/gameListData";
import { LoginResponseBind } from "../../model/LoginResponseBind";
import { Observable, Subscription, timer } from "rxjs";
import { UserInfo } from "../../model/UserInfo";
import { UserInfoResponseBind } from "../../model/UserInfoResponseBind";
import { ApiSlotsService } from "../../services/slots/api-slots.service";
import { responseGamesList } from "../../model/responseGamesList";
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxSpinnerService } from "ngx-spinner";
import { ShareSlotDataService } from "../../services/share-slot-data.service";
import { NgxElectronPlusService } from "ngx-electron-plus";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-home2",
  templateUrl: "./home2.component.html",
  styleUrls: ["./home2.component.scss"],
})
export class Home2Component implements OnInit {
  public user: UserInfo;

  public nomeSito: string;

  public jackpotlow: number;
  public jackpotmed: number;
  public jackpothigh: number;
  public jackpotnational: number;

  public jackpotlowblink: boolean;
  public jackpotmedblink: boolean;
  public jackpothighblink: boolean;
  public jackpotnationalblink: boolean;

  public tipoSlot: string;
  public listaSlot: gameListData[];
  public favorites: gameListData[];
  public showFavorites: boolean;
  public filteredItems: gameListData[];
  //public filteredItemsPaginated : gameListData[];
  public paginaMax: number;
  public paginaCorrente: number;
  public paginaNumeroSlots: number;

  public name: string;
  source: Observable<number>;
  subscribe: Subscription;
  timerPaused = false;
  deviceInfo = null;
  public selectProvider: Vendor;
  public selectSlotType: string;

  private caricamentoInCorso: boolean;
  public isKiosk: boolean;

  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktopDevice: boolean;

  public pathImg = "./assets/images/vendors/shadow/";

  public PROVIDER: Vendor[] = [];
  public idskin: number;
  private sub: any;
  public loginViaKiosk: boolean;
  public loginViaToken: boolean;
  public visPoker: boolean;
  public errorPoker: string;

  public visualizzaJackpot: boolean;
  public showLogout: boolean;
  public showSlide: boolean;

  public catHome: Vendor;

  public isOpenBrands: boolean;

  public isLogin: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private sidebarService: NbSidebarService,
    private apiSlot: ApiSlotsService,
    private deviceService: DeviceDetectorService,
    private dialogService: NbDialogService,
    private spinner: NgxSpinnerService,
    private shareSlotData: ShareSlotDataService,
    private electronPlusService: NgxElectronPlusService
  ) {
    this.isKiosk = false;
    this.user = new UserInfo();
    this.nomeSito = environment.titolo;
    this.jackpotlow = 0;
    this.jackpotmed = 0;
    this.jackpothigh = 0;
    this.jackpotnational = 0;
    this.jackpotlowblink = false;
    this.jackpotmedblink = false;
    this.jackpothighblink = false;
    this.loginViaKiosk = false;
    this.loginViaToken = false;
    this.visPoker = false;
    this.errorPoker = "";
    this.selectProvider = null;
    this.tipoSlot = "";
    this.listaSlot = [];
    this.epicFunction();
    this.caricamentoInCorso = false;
    this.showFavorites = false;
    this.idskin = environment.idskin;

    this.visualizzaJackpot = environment.visualizzaJackpot;
    this.showLogout = environment.showLogout;
    this.showSlide = environment.showSlide;
    this.paginaCorrente = 1;
    this.paginaNumeroSlots = 8;
    this.paginaMax = 1;
    this.catHome = new Vendor();
    this.catHome.title = ""; // vuoto per visualizzare favorite
    this.catHome.id = 99999;
    this.catHome.thumbImage = "home.png";
    this.isOpenBrands = false;
    this.selectSlotType = null;
    this.isLogin = false;
  }

  ngOnInit(): void {
    if (this.electronPlusService.isElectron) {
      this.isKiosk = true;
    }
    this.sub = this.route.params.subscribe((params) => {
      const kiosk = params["kiosk"];
      if (kiosk != "" && kiosk != undefined) {
        this.loginViaKiosk = true;
      }
    });

    this.isLogin = this.authenticationService.isAuthenticated();
    //this.AggiornaSaldo();
    this.start();
    this.loadAll();
  }

  assignCopy(newData: gameListData[], paginaCorrente: number) {
    this.filteredItems = Object.assign([], newData);
    // paginazione commentata per questa versione
    /*if (this.loginViaKiosk == false) {
      this.filteredItems = Object.assign([], newData);
    } else {
    // creiamo la paginazione
      this.filteredItems = this.paginate(newData,paginaCorrente,this.paginaNumeroSlots,this.paginaNumeroSlots);
    }
    */
    if (environment.rimuoviSlotSoloPoker == true) {
      this.filteredItems = [];
      this.visPoker = true;
    }
  }

  filterItem(value) {
    if (!value || (value && value.length < 3)) {
      console.log("assignCopy");
      this.showFavorites = true;
      this.assignCopy(this.listaSlot, this.paginaCorrente);
      return;
    } // when nothing has typed

    console.log("search");

    if (this.caricamentoInCorso == false) {
      this.caricamentoInCorso = true;
      this.filteredItems = [];
      this.showFavorites = false;

      this.spinner.show();

      const newData: gameListData[] = this.listaSlot.filter(function (item) {
        if (!isNaN(Number(value))) {
          return item.id == value;
        } else {
          return item.title.toUpperCase().indexOf(value.toUpperCase()) >= 0;
        }
      });

      this.assignCopy(newData, this.paginaCorrente);

      setTimeout(() => {
        this.spinner.hide();
      }, 500);
      this.caricamentoInCorso = false;
    }
  }

  getClassMenuBar(slotType: string) {
    if (this.selectSlotType != null && slotType == this.selectSlotType) {
      return "btn-menu column flex-center selected puntatore";
    } else {
      return "btn-menu column flex-center puntatore ";
    }
  }

  getClassFooter() {
    if (!this.isDesktopDevice && !this.isTablet) {
      return "footer-wrapper-mobile flex flex-center " + (this.isOpenBrands ? "opened" : "");
    } else {
      return "footer-wrapper flex flex-center " + (this.isOpenBrands ? "opened" : "");
    }
  }

  getClassnavBar(item: Vendor) {
    if (this.selectProvider != null && item.title == this.selectProvider.title) {
      return "nav-item mr-auto mt-1 box active";
    } else {
      return "nav-item mr-auto mt-1 box ";
    }
  }

  AggiornaSaldo() {
    console.log("AggiornaSaldo");

    if (this.isLogin == true) {
      this.authenticationService.getBalance().subscribe(
        (data) => {
          const res = data as UserInfoResponseBind;
          if (res.status == true) {
            this.user = res.data;
            // verifichiamo se ha vinto qualche bonus.
            if (this.user.deleted == 1) {
              this.logOut();
              this.goToUrl(this.user.logOutRedirect);
            }
            if (this.user.bonusWins != null && this.user.bonusWins.length > 0) {
              // vincita bonus visualizziamo il modale.
              /*
                if (this.deviceService.isMobile()) {
                    this.openBonusWin(this.user.bonusWins[0]);
                }
                */
            }
            if (this.user.jackpots != null && this.user.jackpots.length > 0) {
              this.user.jackpots.forEach((element) => {
                if (element.tipo == 81) {
                  this.jackpotlow = element.balance;
                  this.jackpotlowblink = element.blink;
                }
                if (element.tipo == 82) {
                  this.jackpotmed = element.balance;
                  this.jackpotmedblink = element.blink;
                }
                if (element.tipo == 83) {
                  this.jackpothigh = element.balance;
                  this.jackpothighblink = element.blink;
                }
                if (element.tipo == 84) {
                  this.jackpotnational = element.balance;
                  this.jackpotnationalblink = element.blink;
                }
              });
            }
          } else {
            this.logOut();
          }
        },
        (error) => {
          console.log(error);
          this.logOut();
        }
      );
    }
  }

  start() {
    this.source = timer(0, environment.timerAggiornaSaldo);

    this.subscribe = this.source.subscribe((number) => {
      if (!this.timerPaused) {
        this.AggiornaSaldo();
      }
    });
    if (Number(environment.timerAggiornaSaldo) > 9000) {
      this.AggiornaSaldo();
    }
  }

  onPause() {
    this.timerPaused = true;
  }

  onResume() {
    this.timerPaused = false;
  }

  goToUrl(url): void {
    //window.location.href=url;
    if (url == null) {
      window.location.replace(environment.logOutRedirectDefault);
    } else {
      window.location.replace(url);
    }
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
  toggle() {
    this.sidebarService.toggle();
    return false;
  }

  imageClick(result: number) {
    this.changeCategory(this.PROVIDER[Number(result)]);
  }

  goToHome() {
    console.log('goToHome');
    this.paginaMax = 1;
    this.paginaCorrente = 1;
    this.filteredItems = [];
    this.shareSlotData.listaSlot = null;
    this.selectProvider = null;
    this.showSlide = true;
    this.loadAll();
  }

  goToGames() {}

  changeSlotType(slotType: string) {
    console.log('changeSlotType [' + slotType + "] vendor[" + this.selectProvider.title + "]");
    this.isOpenBrands = false;
    if (!slotType || slotType.toUpperCase() == "" || slotType.toUpperCase() == "ALLSLOTS") {
      console.log("assignCopy changeCategory");
      this.selectSlotType = null;
      this.showSlide = true;
      this.showFavorites = true;
      this.paginaMax = Math.ceil(this.listaSlot.length / this.paginaNumeroSlots);
      this.assignCopy(this.listaSlot, this.paginaCorrente);
      return;
    } // when nothing has typed

    if (this.caricamentoInCorso == false) {
      this.caricamentoInCorso = true;
      this.filteredItems = [];
      this.showFavorites = false;

      this.spinner.show();
      
      const newData: gameListData[] = this.listaSlot.filter(function (item) {
          /*if (slotType.toUpperCase() == 'GAMES') {
            if (item.type.toUpperCase() == "CASINO" || 
              item.type.toUpperCase() == "LOTTERY" || 
              item.type.toUpperCase() == "VIRTUAL_SPORTS" || 
              item.type.toUpperCase() == "ROCKETMAN"
            || item.type.toUpperCase() == "VIDEO SLOT" ) {
             return true;
            } else {
              return false;
            }
          } else  {
            */
            return item.type.toUpperCase() == slotType.toUpperCase() 
          //}
            
        
      });
      this.paginaMax = Math.ceil(newData.length / this.paginaNumeroSlots);
      this.assignCopy(newData, this.paginaCorrente);

      setTimeout(() => {
        this.spinner.hide();
      }, 500);
      this.caricamentoInCorso = false;
      this.showSlide = false;
    }

    if (slotType.toUpperCase() == "POKER") {
      this.visPoker = true;
      console.log("this.visPoker = true");
    }
    this.selectSlotType = slotType.toUpperCase();
  }

  changeCategoryTableGames() {
    let cat: Vendor[];
    cat = this.PROVIDER.filter( item => item.title=="TABLEGAMES")
    console.log(cat);
    
    if (cat.length > 0) {
      this.changeCategory(cat[0]);
    }
    
  }

  changeCategory(cat: Vendor) {
    //console.log('changeCategory [' + cat.id + "] vendor[" + cat.title + "]");
    this.visPoker = false;
    this.errorPoker = "";
    this.selectProvider = cat;
    this.paginaCorrente = 1;
    this.selectSlotType = null;
    this.isOpenBrands = false;
    if (!cat || cat.title.toUpperCase() == "" || cat.title.toUpperCase() == "ALLSLOTS") {
      console.log("assignCopy changeCategory");
      this.showSlide = true;
      this.showFavorites = true;
      this.paginaMax = Math.ceil(this.listaSlot.length / this.paginaNumeroSlots);
      this.assignCopy(this.listaSlot, this.paginaCorrente);
      return;
    } // when nothing has typed

    if (this.caricamentoInCorso == false) {
      this.caricamentoInCorso = true;
      this.filteredItems = [];
      this.showFavorites = false;

      this.spinner.show();

      const newData: gameListData[] = this.listaSlot.filter(function (item) {
        return item.vendor.toUpperCase() == cat.title.toUpperCase();
      });
      this.paginaMax = Math.ceil(newData.length / this.paginaNumeroSlots);
      this.assignCopy(newData, this.paginaCorrente);

      setTimeout(() => {
        this.spinner.hide();
      }, 500);
      this.caricamentoInCorso = false;
      this.showSlide = false;
    }

    if (cat.title.toUpperCase() == "POKER") {
      this.visPoker = true;
    }
  }

  loadAll() {
    if (this.caricamentoInCorso == false) {
      this.caricamentoInCorso = true;
      this.listaSlot = [];
      this.selectProvider = null;
      this.spinner.show();

      if (this.shareSlotData.listaSlot == null || this.shareSlotData.favorites == null) {
        if (this.isLogin == true && environment.rimuoviSlotSoloPoker == false) {
          this.apiSlot.getTop20(environment.idskin, this.isMobile).subscribe(
            (data) => {
              const res = data as responseGamesList;
              if (res.status == true) {
                this.favorites = res.data;
                this.shareSlotData.favorites = this.listaSlot;
                this.showFavorites = true;
              }
              setTimeout(() => {
                this.spinner.hide();
                this.caricamentoInCorso = false;
              }, 500);
            },
            (error) => {
              console.log(error);
              setTimeout(() => {
                this.spinner.hide();
                this.caricamentoInCorso = false;
                this.showFavorites = false;
              }, 500);
            }
          );
        }
        this.apiSlot.gameListNew(environment.idskin, this.isMobile).subscribe(
          (data) => {
            const res = data as responseGamesList;
            if (res.status == true) {
              this.listaSlot = res.data;
              this.shareSlotData.listaSlot = this.listaSlot;
              this.PROVIDER = res.vendors;
              console.log(res);
              if (environment.rimuoviPokerTemporaneo) {
                let i = 0;
                while (i < this.PROVIDER.length) {
                  if (this.PROVIDER[i].title === "POKER") {
                    this.PROVIDER.splice(i, 1);
                  } else {
                    ++i;
                  }
                }
              }
              if (environment.rimuoviAllSlots) {
                let i = 0;
                while (i < this.PROVIDER.length) {
                  if (this.PROVIDER[i].title === "ALLSLOTS") {
                    this.PROVIDER.splice(i, 1);
                  } else {
                    ++i;
                  }
                }
              }
              if (environment.rimuoviSlotSoloPoker) {
                let i = 0;
                while (i < this.PROVIDER.length) {
                  if (this.PROVIDER[i].title !== "POKER") {
                    this.PROVIDER.splice(i, 1);
                  } else {
                    ++i;
                  }
                }
              }
              //this.PROVIDER.push(this.catHome);
              this.shareSlotData.PROVIDER = this.PROVIDER;
              if (this.favorites == null || (this.favorites != null && this.favorites.length == 0)) {
                this.getRandomSlot();
              }
              this.assignCopy(this.listaSlot, this.paginaCorrente);
              //this.getRandomSlot();
            }

            setTimeout(() => {
              this.spinner.hide();
              this.caricamentoInCorso = false;
            }, 500);
          },
          (error) => {
            console.log(error);
            setTimeout(() => {
              this.spinner.hide();
              this.caricamentoInCorso = false;
            }, 500);
          }
        );
      } else {
        // listaSlot gia caricato in precedenza
        this.caricamentoInCorso = false;
        this.listaSlot = this.shareSlotData.listaSlot;
        this.favorites = this.shareSlotData.favorites;
        this.PROVIDER = this.shareSlotData.PROVIDER;
        if (this.shareSlotData.vendor != null) {
          this.changeCategory(this.shareSlotData.vendor);
        } else {
          this.assignCopy(this.listaSlot, this.paginaCorrente);
        }
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }
  }

  logIn() {
    this.router.navigate(["/login"]);
  }

  logOut() {
    this.authenticationService.logout();

    if (this.electronPlusService.isElectron) {
      this.router.navigate(["/kiosk/install"]);
    } else {
      if (this.loginViaKiosk) {
        this.router.navigate(["/ricariche/1"]);
      } else {
        this.router.navigate([environment.defaultPageNotLoggeg]);
      }
    }
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
  }

  openLobbyPoker() {
    console.log("openLobbyPoker home");
    if (this.isLogin == true) {
      this.authenticationService.getPokerToken().subscribe(
        (data) => {
          const res = data as LoginResponseBind;
          if (res.token.indexOf("|") > 0) {
            // errore
            const splitstring = res.token.split("|");
            this.errorPoker = splitstring[1];
          } else {
            // apriamo nuova pagina
            window.open(environment.pokerlobby + "/wstoken.aspx?token=" + res.token );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  openDialog(item: gameListData) {
    console.log("openDialog");
    if (this.isLogin == true) {
      /*
    if (this.deviceService.isMobile()) {
      this.apiSlot.play(item.product, item.id, "mobile","EUR","IT","real","").subscribe(
        data => {
            const res = data as responsePlay;
            if (res.status == true) {
              console.log (' res data[' + res.data + ']');
                window.open(res.data as string, '_blank');
            } else {
              console.log('errore play code[' + res.code + '] error[' + res.error + ']' );
            }
        },
        error => {
            console.log(error);
        });
        
    } else {
     
      const dialogRef = this.dialogService.open(SlotDialogComponent, { hasBackdrop: true, closeOnBackdropClick: true, closeOnEsc : true,  context: {
        game: item,
        isMobile : this.isMobile,
        isTablet : this.isTablet,
        isDesktopDevice : this.isDesktopDevice, 
      }, dialogClass : 'model-full'
      });
  
    }
    */
      this.shareSlotData.game = item;
      this.shareSlotData.vendor = this.selectProvider;
      if (this.loginViaKiosk) {
        this.router.navigate(["/openslot/1"]);
      } else {
        this.router.navigate(["/openslot"]);
      }
    }
  }

  /*
  openBonusWin(item : BonusWinBind  ) {
    this.onPause();
    const dialogRef = this.dialogService.open(BonusWinDialogComponent, { hasBackdrop: true, closeOnBackdropClick: true, closeOnEsc : true,  context: {
      bonusWin: item,
      userid : this.user.id
    }
    }).onClose.subscribe( data => { 
        if (data == true) {
           this.AggiornaSaldo();
        }
        this.onResume();
      }
    );
   
  }
*/

  getRandomSlot() {
    let v: Vendor;
    const min = 1;
    const max = this.PROVIDER.length;
    const random = Math.floor(Math.random() * (max - min)) + min;
    v = this.PROVIDER[random];
    this.changeCategory(v);
  }

  paginate(res, page, page_size: number, max_page_size) {
    page = page || 1;
    page_size = page_size || 10;
    max_page_size = max_page_size || 100;
    page_size = page_size > max_page_size ? max_page_size : page_size;
    const pages = Math.ceil(res.length / page_size),
      items = [];
    for (let p = 1; p <= pages; p++) {
      const start = page_size * (p - 1);
      items.push(res.slice(start, start + page_size));
    }
    return page <= items.length ? items[page - 1] : [];
  }

  paginaPrecedente() {
    if (this.selectProvider != null) {
      const newData: gameListData[] = this.filterNewData(this.listaSlot, this.selectProvider);
      /*
      newData = this.listaSlot.filter(function(item) {
        debugger;
        return item.vendor.toUpperCase() == this.selectProvider.title.toUpperCase();
      });
      */
      this.paginaCorrente = this.paginaCorrente - 1;
      this.paginaMax = Math.ceil(newData.length / this.paginaNumeroSlots);
      if (this.paginaCorrente < 1) {
        this.paginaCorrente = this.paginaMax;
      }
      this.assignCopy(newData, this.paginaCorrente);
    }
  }

  paginaSuccessiva() {
    if (this.selectProvider != null) {
      const newData: gameListData[] = this.filterNewData(this.listaSlot, this.selectProvider);
      /*newData = this.listaSlot.filter(function(item) {
        debugger;
        return item.vendor.toUpperCase() == this.selectProvider.title.toUpperCase();
      });
      */

      this.paginaMax = Math.ceil(newData.length / this.paginaNumeroSlots);

      if (this.paginaCorrente < this.paginaMax && this.paginaCorrente * this.paginaNumeroSlots < newData.length) {
        this.paginaCorrente = this.paginaCorrente + 1;
      } else {
        this.paginaCorrente = 1;
      }
      this.assignCopy(newData, this.paginaCorrente);
    }
  }

  filterNewData(newData, criteria) {
    return newData.filter(function (item) {
      return item.vendor.toUpperCase() == criteria.title.toUpperCase();
    });
  }

  switchBrands() {
    this.isOpenBrands = !this.isOpenBrands;
  }
}
