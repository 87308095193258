import { Injectable } from '@angular/core';
import { gameListData } from '../model/gameListData';
import { Vendor } from '../model/Vendor';

@Injectable({
  providedIn: 'root'
})
export class ShareSlotDataService {

  public game: gameListData;
  public vendor : Vendor;
  public listaSlot : gameListData[];
  public  PROVIDER: Vendor[]
  public favorites : gameListData[];
  constructor() {
    this.game = null;
    this.vendor = null;
    this.listaSlot = null;
    this.PROVIDER  = null;
   }
}
