import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { NgxElectronPlusService } from 'ngx-electron-plus';
import { environment } from '../../../environments/environment';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public router: Router,
      private authenticationService: AuthenticationService,private electronPlusService: NgxElectronPlusService) {}
    canActivate(): boolean {
      const currentUser = this.authenticationService.currentUserValue;
      if (currentUser) {
          // logged in so return true
          return true;
      }
      if (this.electronPlusService.isElectron) {
        this.router.navigate(['/kiosk/install']);
      } else {
        this.router.navigate([environment.defaultPageNotLoggeg]);
      }
      return false;
    }
  }