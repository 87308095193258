import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "../../services/Auth/authentication.service";
import { environment } from "../../../environments/environment";
import { LoginResponseBind } from "../../model/LoginResponseBind";

@Component({
  selector: "app-onlypoker",
  templateUrl: "./onlypoker.component.html",
  styleUrls: ["./onlypoker.component.scss"],
})
export class OnlypokerComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";
  public nomeSito: String;
  public redirectTo: String;
  public idskin: number;

  private userNameField: string;
  private passwordField: string;
  private token: string;

  private sub: any;
  private subQ: any;
  public loginViaKiosk: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.idskin = environment.idskin;
    this.nomeSito = environment.titolo;
    this.redirectTo = "/" + environment.defaultPageNotLoggeg;
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/"]);
    }
    this.userNameField = "";
    this.passwordField = "";

    this.loginViaKiosk = false;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.userNameField = params["username"];
      this.passwordField = params["password"];
    });
    this.subQ = this.route.queryParams.subscribe((params) => {
      this.token = params.token;
    });
    //this.token = this.route.snapshot.queryParamMap.get('token');
    if (
      this.userNameField != "" &&
      this.userNameField != undefined &&
      this.passwordField != "" &&
      this.passwordField != undefined
    ) {
      this.loginViaKiosk = true;
    } else {
      this.userNameField = "";
      this.passwordField = "";
    }
    this.loginForm = this.formBuilder.group({
      username: [this.userNameField, Validators.required],
      password: [this.passwordField, Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    if (this.token != "" && this.token != undefined) {
      localStorage.removeItem(environment.userCookei);
      this.authenticationService.loginByToken(this.token);
      this.router.navigate([this.returnUrl]);
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.error = "";

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          if (this.loginViaKiosk) {
            this.router.navigate(["/home/1"]);
          } else {
            this.router.navigate([this.returnUrl]);
            this.openLobbyPoker();
          }
        },
        (error) => {
          this.error = error;
          this.loading = false;
        }
      );
  }

  openLobbyPoker() {
    console.log("openLobbyPoker onlypoker");

    this.authenticationService.getPokerToken().subscribe(
      (data) => {
        const res = data as LoginResponseBind;
        if (res.token.indexOf("|") > 0) {
          // errore
          var splitstring = res.token.split("|");
          this.error = splitstring[1];
        } else {
          // apriamo nuova pagina
          window.open(
            environment.pokerlobby + "/wstoken.aspx?token=" + res.token
          );
          // + ("&twoversion=1");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
