import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiSlotsService {

  constructor(private http: HttpClient) { }


  gameListNew(idskin: Number, isMobile : Boolean) {
    const token = JSON.parse(localStorage.getItem(environment.userCookei));
    return this.http.get(environment.apiSlotEndpoint + '/games/' + idskin + '/list?isMobile=' + Boolean(isMobile));
  }

  play(idskin : Number, gameid : Number, device : String, curreny : String, language : String, mode : String, exit : String)  {
    const token = JSON.parse(localStorage.getItem(environment.userCookei));
    if (token !== null) {
    
      return this.http.get(environment.apiSlotEndpoint + '/session/' + idskin + '/' + gameid + '/' + device);
    } else {
      return null;
    }
  }

  getTop20(idskin: Number, isMobile : Boolean) {
    const token = JSON.parse(localStorage.getItem(environment.userCookei));
    return this.http.get(environment.apiSlotEndpoint + '/games/' + idskin + '/list/top20?isMobile=' + Boolean(isMobile));
  }
  

}
