import { Component } from '@angular/core';
import { ElectronService } from './core/services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { TotemPerifericheService } from './services/totem-periferiche.service';
import { User } from './model/User';
import { AuthenticationService } from './services/Auth/authentication.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentUser: User;
  constructor(
    private electronService: ElectronService,
    private translate: TranslateService,private _totemPerifericheService : TotemPerifericheService,
    private authenticationService: AuthenticationService, private titleService: Title
  ) {
    this.translate.setDefaultLang('en');
    console.log('AppConfig', environment);
    this.authenticationService.logout();
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    console.log('currentUser : ' + this.currentUser);
    this.titleService.setTitle(environment.titolo + ' - ' + environment.idskin);
    if (electronService.isElectron) {
      console.log(process.env);
      console.log('Run in electron');
      console.log('Electron ipcRenderer', this.electronService.ipcRenderer);
      console.log('NodeJS childProcess', this.electronService.childProcess);
      this._totemPerifericheService.getProfileObs().subscribe({
        next(position) {
          console.log('Current valore: ', position);
        },
        error(msg) {
          console.log('Error Getting Location: ', msg);
        }
      });
    } else {
      console.log('Run in browser');
    }
  }
}
