import { Component, OnInit } from '@angular/core';
import { NgxElectronPlusService } from 'ngx-electron-plus';
import { environment } from '../../../../environments/environment';
import { ExistsResult } from 'fs-jetpack/types';
import { configKiosk } from '../../../model/kiosk/configKiosk'
import { TranslateService } from '@ngx-translate/core';
import { Console } from 'console';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../services/Auth/authentication.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss']
})
export class InstallComponent implements OnInit {

  public getAppPath : string;
  public getAppPathExe : string;
  public getAppPathUserData : string;
  public macAddress : string;
  public key:string;
  public strinErrore : string;
  public errore : boolean;  
  
  public schermataInstallazione : boolean;
  public schermataFine : boolean;
  
  constructor(private electronPlusService: NgxElectronPlusService,private translate: TranslateService, private router: Router,private authenticationService: AuthenticationService) {

    this.schermataInstallazione = false;
    this.schermataFine = false;
    this.key = '';
    this.strinErrore = '';
    this.errore = false;
   }

  ngOnInit(): void {
    if (this.electronPlusService.isElectron) {
      this.getAppPath =  this.electronPlusService.remote.app.getAppPath();
      this.getAppPathExe  = this.electronPlusService.remote.app.getPath('exe');
      this.getAppPathUserData = this.electronPlusService.remote.app.getPath('userData');
     
     
      let res : ExistsResult =  this.electronPlusService.fs.exists(this.getAppPathUserData + '/' + environment.configFile);
      console.log('getAppPath [' + this.getAppPath + ']');
      console.log('getAppPathExe [' + this.getAppPathExe + ']');
      console.log('getAppPathUserData [' + this.getAppPathUserData + ']');
      if (res.valueOf() == 'file' ) {
        // leggiamo il contenuto
        let rawdata = this.electronPlusService.fs.read(this.getAppPathUserData + '/' + environment.configFile,'utf8');
        let conf : configKiosk = JSON.parse(rawdata);
        if (conf != null && conf.key != '') {
            console.log('da installare');
            this.schermataInstallazione = false;
            this.schermataFine = true;
            this.redirectLogin(conf.key);
        } else {
          this.schermataInstallazione = true;        
        }
      } else {
          this.schermataInstallazione = true;        
      }
    } else {
      console.log('non electron app');
    }
  }

  save(valore) {
    console.log('KEY  : ' + valore);
    if (valore.trim()!='') {
      let conf : configKiosk =  new configKiosk();
      conf.key = valore;
      conf.apiAuthEndpoint = environment.apiAuthEndpoint;
      let data = JSON.stringify(conf);
      this.electronPlusService.fs.write(this.getAppPathUserData + '/' + environment.configFile, data );
      //C:\Users\Marco\AppData\Roaming
      // creiamo i file nella cartella FILEREPORT per la gestione delle banconote
      let res : ExistsResult =  this.electronPlusService.fs.exists(this.getAppPathUserData + '/FILEREPORT');
      if (res.valueOf() != 'dir') {
        this.electronPlusService.fs.dir(this.getAppPathUserData + '/FILEREPORT');
      }
      if (res.valueOf() == 'dir') {
        // creiamo i file
        this.electronPlusService.fs.write(this.getAppPathUserData + '/FILEREPORT/incasso.txt','-1' );
        this.electronPlusService.fs.write(this.getAppPathUserData + '/FILEREPORT/SETTAIKO.txt' , '2' );
        this.electronPlusService.fs.write(this.getAppPathUserData + '/FILEREPORT/taiko.txt' , '' );
      }
      this.schermataInstallazione = false;
      this.schermataFine = true;
      this.redirectLogin(valore);
    }  
  }


  redirectLogin(valore) {
    this.authenticationService.loginKiosk(valore)
    .pipe(first())
    .subscribe(
        data => {
          setTimeout(()=>{                          
            this.router.navigate(['/']);
            }, 4000);      
        },
        error => {
          
            this.strinErrore = error;
            this.errore = true;
        });
    
  
  }
  // fine
}
