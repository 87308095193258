import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./services/Auth/AuthGuard.service";
import { LoginComponent } from "./pages/login/login.component";
import { Home2Component } from "./pages/home2/home2.component";
import { RicaricheComponent } from "./pages/ricariche/ricariche.component";
import { OpenSlotComponent } from "./pages/open-slot/open-slot.component";
import { LogoutComponent } from "./pages/logout/logout.component";
import { OnlypokerComponent } from "./pages/onlypoker/onlypoker.component";

const routes: Routes = [
  { path: "", component: Home2Component, canActivate: [AuthGuardService] },
  //{ path: "", component: OnlypokerComponent },
  { path: "home", component: Home2Component },
  {
    path: "home/:kiosk",
    component: Home2Component,
    canActivate: [AuthGuardService],
    /*,
    children: [
      {
        path: 'kiosk',
        loadChildren: './pages/kiosk/kiosk.module#KioskModule'
      }
    ]
      */
  },
  { path: "onlypoker", component: OnlypokerComponent },
  { path: "kiosk", loadChildren: "./pages/kiosk/kiosk.module#KioskModule" },
  { path: "ricariche", component: RicaricheComponent },
  { path: "ricariche/:kiosk", component: RicaricheComponent },
  { path: "login", component: LoginComponent },
  { path: "login/:username/:password", component: LoginComponent },

  { path: "logout", component: LogoutComponent },
  { path: "openslot", component: OpenSlotComponent },
  { path: "openslot/:kiosk", component: OpenSlotComponent },
  // otherwise redirect to home
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
