import { Injectable } from '@angular/core';
import {    Router,
    CanActivate,
    ActivatedRouteSnapshot
  } from '@angular/router';
  
  import decode from 'jwt-decode';

  
import { environment } from '../../../environments/environment'; 
import { AuthenticationService } from './authentication.service';

@Injectable()
export class RoleGuardService implements CanActivate {
    constructor(private authenticationService: AuthenticationService, public router: Router) {}
    canActivate(route: ActivatedRouteSnapshot): boolean {
      // this will be passed from the route config
      // on the data property
      const expectedRole = route.data.expectedRole;
      const token = localStorage.getItem(environment.userCookei);
      // decode the token to get its payload
      let tokenPayload = decode(token);
      console.log(tokenPayload['role']);
      if (
        !this.authenticationService.isAuthenticated() ||
        tokenPayload['role'] !== expectedRole
      ) {
        this.router.navigate(['authentication/signin']);
        return false;
      }
      return true;
    }
  }