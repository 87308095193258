import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { TotemPerifericheService } from './services/totem-periferiche.service'
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppRoutingModule } from './app-routing.module';

// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { LoginComponent } from './pages/login/login.component';
import { JwtInterceptor } from './services/JwtInterceptor';
import { ErrorInterceptor } from './services/Auth/ErrorInterceptor';
import { RoleGuardService } from './services/Auth/RoleGuard.service';
import { AuthGuardService } from './services/Auth/AuthGuard.service';
import { AuthenticationService } from './services/Auth/authentication.service';
import { AppComponent } from './app.component';
import { Home2Component } from './pages/home2/home2.component';
import { DeviceDetectorService  } from 'ngx-device-detector';
import { RicaricheComponent } from './pages/ricariche/ricariche.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { OpenSlotComponent } from './pages/open-slot/open-slot.component';
import { NbThemeModule, NbSidebarModule, NbSidebarComponent, NbLayoutModule, NbSidebarService, NbLayoutComponent, NbActionsComponent, NbActionsModule, NbDialogModule, NbToastrModule, NbCardModule } from '@nebular/theme';
import { ToggleFullscreenDirective } from './directive/toggle-fullscreen.directive';
import { NgImageSliderModule } from 'ng-image-slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KioskModule} from './pages/kiosk/kiosk.module';
import { LogoutComponent } from './pages/logout/logout.component';
import { OnlypokerComponent } from './pages/onlypoker/onlypoker.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


// https://jasonwatmore.com/post/2020/04/19/angular-9-jwt-authentication-example-tutorial

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  scrollbar : false,
  allowSlideNext: true,
  allowSlidePrev: true,
  allowTouchMove : true,
  autoplay: true,
  autoHeight: false,
  pagination: false,
  navigation:  true
 
};


@NgModule({
  declarations: [AppComponent,
    LoginComponent,
    Home2Component,
    RicaricheComponent,
    ToggleFullscreenDirective,
    OpenSlotComponent,
    LogoutComponent,
    OnlypokerComponent
 
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SwiperModule,
    NbLayoutModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbSidebarModule.forRoot(),
    NbDialogModule.forRoot(),
    NgxSpinnerModule,
    NgImageSliderModule,
    NbToastrModule.forRoot(),
    NbCardModule
    ,KioskModule
  
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    RoleGuardService, AuthGuardService, AuthenticationService,
    DeviceDetectorService ,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    NbSidebarService,
    Title,
    TotemPerifericheService],
  bootstrap: [AppComponent]
})
export class AppModule {}
