import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KioskRoutingModule } from './kiosk-routing.module';
import { InstallComponent } from './install/install.component';
import { TranslateModule } from '@ngx-translate/core';
import { NbInputModule,NbCardModule,NbLayoutModule } from '@nebular/theme';
@NgModule({
  declarations: [InstallComponent],
  imports: [
    CommonModule,
    KioskRoutingModule,
    NbInputModule,
    NbCardModule,
    TranslateModule,
    NbLayoutModule
  ]
})
export class KioskModule { }
