export const environment = {
  production: false,
  userCookei: "currentUser",
  environment: "LOCAL",
  apiSlotEndpoint: 'https://staging.api.slots.connector.gamingeva.com/api/v1',
  apiAuthEndpoint: 'https://staging.api.slots.connector.gamingeva.com/api/v1',
  //apiSlotEndpoint: "https://api.slots.connector.gamingeva.com/api/v1",
  //apiAuthEndpoint: "https://api.slots.connector.gamingeva.com/api/v1",
  //apiSlotEndpoint: "https://api.slots.connector.extremeslot.pro/api/v1",
  //apiAuthEndpoint: "https://api.slots.connector.extremeslot.pro/api/v1",
  //apiSlotEndpoint: 'http://localhost:50593/api/v1',
  //apiAuthEndpoint: 'http://localhost:50593/api/v1',

  titolo: "lordpoker", //daygames24 //lordpoker //GkProvider Casino //casinoadmiral.org
  traceRoute: true,
  idskin: 1351, //1352 staging, 1415 = acer2277 , 1132 test, 1132 ricarica365 , 1552 lordpoker.net, 1641 gkprovider
  //email: "ricaricatimobile@gmail.com",
  email: "dir.evagaming@gmail.com",
  // defaultPageNotLoggeg: "home",
  //defaultPageNotLoggeg : 'ricariche',
  defaultPageNotLoggeg: "login",
  // defaultPageNotLoggeg: "onlypoker",
  configFile: "configure.json",
  logOutRedirectDefault: "https://news.google.it",
   pokerlobby: "http://cdn.lordpoker.net",
  pokerBannerActive: false,
  timerAggiornaSaldo: 60000, //2000, //
  visualizzaJackpot: true,
  showLogout: true,
  showSlide: true,
  showSaldoUtenteAperturaSlot: true,
  rimuoviPokerTemporaneo: true,
  rimuoviAllSlots: true,
  rimuoviSlotSoloPoker: false,
};
