import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './Auth/authentication.service';
import { environment } from '../../environments/environment';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.token;
        const isApiUrl = request.url.startsWith(environment.apiSlotEndpoint);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({ headers: request.headers.append('Access-Control-Allow-Origin', '*')
            .append('Authorization',`Bearer ${currentUser.token}`)
            
        
            });
            /*
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
            */
        } else {
            request = request.clone({ headers: request.headers.append('Access-Control-Allow-Origin', '*')});
        }

        return next.handle(request);
    }
}