import { Injectable } from '@angular/core';
import { ExistsResult } from 'fs-jetpack/types';

import { NgxElectronPlusService } from 'ngx-electron-plus';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TotemPerifericheService {
  source: Observable<number>;
  subscribe: Subscription;
  timerPaused: boolean = false;

  private pathFile: string = 'C:\\FILEREPORT\\incasso.txt';
  
  private profileObs$: BehaviorSubject<String> = new BehaviorSubject(null);

  getProfileObs(): Observable<String> {
      return this.profileObs$.asObservable();
  }

  setProfileObs(profile: String) {
      this.profileObs$.next(profile);
  }
  
  constructor(private electronPlusService: NgxElectronPlusService) { 

    if(this.electronPlusService.isElectron) {
        this.start();
    }

  }


  public readFile() {
    if(this.electronPlusService.isElectron) {
      var res: ExistsResult = this.electronPlusService.fs.exists(this.pathFile);
      //console.log('fileExist :' + res);
      var ris: string = this.electronPlusService.fs.read(this.pathFile,'utf8');
      // procedura
      // a) leggo il file
      // b) se valore > 0 soldi inseriti -> scrivo nel file -1 e chiamo evento inserimento
      // altrimenti se valore <=0 non faccio nulla
      var a = parseInt(ris);
      
      if (a > 0) {
        this.onPause();
        this.setProfileObs(ris);
      }
      
    }
  }



  
 public start() {
   
    this.source = timer(0, 1000);
    
    this.subscribe = this.source
      .subscribe(number => {
        if(!this.timerPaused) {
          //console.log(' tick : ' + number);
          this.readFile();
        }
      });
 
}

public onPause() {
  this.timerPaused = true;
  this.electronPlusService.fs.write(this.pathFile, '-1');
}

public onResume() {
  this.timerPaused = false;
  this.electronPlusService.fs.write(this.pathFile, '-1');
}


ngOnDestroy() {
  if (this.subscribe != null) {
   this.subscribe.unsubscribe();
  }
}

}
