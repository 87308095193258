import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../../model/User";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import decode from "jwt-decode";
import { RequestBonusWinBind } from "../../model/RequestBonusWinBind";
import { ResponseBonusWinBind } from "../../model/ResponseBonusWinBind";
import { LoginBind } from "../../model/LoginBind";
import { configKiosk } from "../../model/kiosk/configKiosk";
import { ShareSlotDataService } from "../share-slot-data.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private shareSlotData: ShareSlotDataService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem(environment.userCookei))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  loginByToken(token: string) {
    var loginToken: User;
    loginToken = new User();
    loginToken.token = token;
    localStorage.setItem(environment.userCookei, JSON.stringify(token));
    this.currentUserSubject.next(loginToken);
  }

  loginKiosk(key: string) {
    var l = new configKiosk();
    l.key = key;
    return this.http
      .post<any>(environment.apiAuthEndpoint + "/auth/kiosk/login", l)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(environment.userCookei, JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  login(username: string, password: string) {
    var l = new LoginBind();
    l.username = username;
    l.password = password;
    l.idskin = environment.idskin;
    return this.http
      .post<any>(environment.apiAuthEndpoint + "/auth/login", l)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem(environment.userCookei, JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(environment.userCookei);
    this.shareSlotData.listaSlot = null;
    this.currentUserSubject.next(null);
  }

  isAuthenticated(): boolean {
    const token = JSON.parse(localStorage.getItem(environment.userCookei));
    if (token !== null) {
      const cookie = JSON.parse(localStorage.getItem(environment.userCookei));
      // Check whether the token is expired and return
      // true or false
      return !this.isTokenExpired(cookie.token);
    } else {
      return false;
    }
  }

  isTokenExpired(token: string, offsetSeconds?: number): boolean {
    const date = this.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;

    if (date == null) {
      return false;
    }

    // Token expired?
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  getTokenExpirationDate(token: string): Date {
    let decoded: any;
    decoded = decode(token);

    if (!decoded.hasOwnProperty("exp")) {
      return null;
    }

    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  getBalance(checkJackpot: Boolean = false) {
    const token = JSON.parse(localStorage.getItem(environment.userCookei));
    if (token !== null) {
      var strUrl = environment.apiAuthEndpoint + "/auth/balance";
      if (checkJackpot == true) {
        strUrl += "?checkJackpot=true";
      }
      return this.http.get(strUrl);
    } else {
      console.log("token null");
    }
  }

  ritiraBonus(idBonusWin: number, userid: number) {
    const token = JSON.parse(localStorage.getItem(environment.userCookei));
    if (token !== null) {
      let req: RequestBonusWinBind = new RequestBonusWinBind();
      req.idBonusWin = idBonusWin;
      req.userId = userid;
      return this.http.post<ResponseBonusWinBind>(
        environment.apiAuthEndpoint + "/auth/bonus/request",
        req
      );
    } else {
      console.log("token null");
    }
  }

  getPokerToken() {
    const token = JSON.parse(localStorage.getItem(environment.userCookei));
    if (token !== null) {
      var strUrl = environment.apiAuthEndpoint + "/auth/poker/token";
      return this.http.get(strUrl);
    } else {
      console.log("token null");
    }
  }
}
