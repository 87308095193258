export class User {
    public nickname: String;
    public idUser: number;
    public role: String;
    public token?: String;

    constructor() {
        this.nickname = '';
        this.idUser = -1;
        this.role = "";
        this.token = "";
    }
}
